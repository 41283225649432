<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In an aqueous workup of a reaction, determine which solvent is located in the separatory
        funnel. Drag the labels to the appropriate layers in the beaker. Refer to the densities of
        solvents if needed.
      </p>

      <drag-to-rank-input
        v-model="inputs.studentAnswersA"
        :items="optionsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="Top Layer"
        append-text=""
      />

      <drag-to-rank-input
        v-model="inputs.studentAnswersB"
        :items="optionsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="Bottom Layer"
        append-text=""
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LB_A8Q8',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswersA: [],
        studentAnswersB: [],
      },
      options: ['organic plate', 'diethyl ether', 'aqueous phase', 'water'],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
